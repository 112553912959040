<template>
  <div class="topic">
    <div class="transfer_index">
      <div class="transfer loading">
        <el-table ref="multipleTable" v-loading="isLeftLoading" rules="rows" :data="leftAllList" tooltip-effect="dark" style="width: 100%" border>
          <el-table-column width="500" :show-overflow-tooltip="true">
            <template slot="header" slot-scope="{}">
              <div class="header_table">
                <el-checkbox :indeterminate="isLeftIndeterminate" v-model="isLeftCheckAll" @change="leftCheckChange"></el-checkbox>
                <el-input style="width:150px" placeholder="请输入内容" prefix-icon="el-icon-search" v-model="leftParams.keyWord" clearable>
                </el-input>

                <el-cascader collapse-tags v-model="projectId" :options="dictList" :props="props" @change="handleChange" clearable></el-cascader>
              </div>
            </template>
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isTrue" :disabled="scope.row.disabled"></el-checkbox>
              <span style="margin-left: 10px">{{ scope.row.question }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination small layout="total,prev, pager, next" :current-page.sync="leftParams.pageNum" :total="leftTotal" @current-change="pageNumLeftChange">
          </el-pagination>
        </div>
      </div>

      <div>
        <el-button type="primary" icon="el-icon-arrow-left" @click="leftClick"></el-button>
        <el-button type="primary" icon="el-icon-arrow-right" @click="rightClick"></el-button>
      </div>
      <div class="transfer loading">
        <div class="transfer">
          <el-table ref="multipleTable" v-loading="isRightLoading" :data="currentRightList" tooltip-effect="dark" style="width: 100%" border>
            <el-table-column label="日期" width="500" :show-overflow-tooltip="true">
              <template slot="header" slot-scope="{}">
                <div class="header_table">
                  <el-checkbox :indeterminate="isRightIndeterminate" v-model="isRightCheckAll" @change="checkboxChange"></el-checkbox>
                  <div>
                    <span>{{ rightAllList.length + "/" + questionNum }}</span>
                    <el-button style="margin-left: 20px" type="primary" @click="topicGetClick">查看选题</el-button>
                    <el-button type="primary" @click="subClick">提交</el-button>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isTrue" @change="handleCheckChange(scope.row)"></el-checkbox>
                <span style="margin-left: 10px">{{ scope.row.question }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination small :hide-on-single-page="total == 0" layout="total,prev, pager, next" :total="rightAllList.length" @current-change="rightPageNumChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { Decrypt } from "@/utils/common/encryp";

export default {
  name: "AdminDemoIndex",

  data() {
    return {
      leftTotal: 0,
      dictList: [],
      leftParams: {
        pageNum: 1,
        pageSize: 10,
        questionType: 2,
        keyWord: "",
        tags: [],
        companyNo: "",
      },
      leftParamskeyWord: "",

      projectId: "",
      props: {
        label: "name",
        value: "id",
        multiple: true,
        emitPath: true,
      },
      leftAllList: [],
      isLeftIndeterminate: false,
      isLeftCheckAll: false,
      rightParams: {
        questionType: 2,
        keyWord: "",
        tags: [],
        companyNo: "",
      },
      isRightIndeterminate: false,
      isRightCheckAll: false,
      currentRightPageNum: 1,
      rightAllList: [],
      currentRightList: [],
      questionNum: 0,
      isLeftLoading: false,
      isRightLoading: false,
    };
  },
  watch: {
    leftParams: {
      deep: true, //深度监听设置为 true
      handler: async function () {
        const res = await this.leftList();
        if (res) {
          this.aboutMore();
        }
      },
    },
    leftAllList: {
      deep: true, //深度监听设置为 true
      handler: function () {
        const { checkAll, indeterminate } = this.setCheck(this.leftAllList);
        this.isLeftCheckAll = checkAll;
        this.isLeftIndeterminate = indeterminate;
      },
    },
    currentRightList: {
      deep: true, //深度监听设置为 true
      handler() {
        const { checkAll, indeterminate } = this.setCheck(
          this.currentRightList
        );
        this.isRightCheckAll = checkAll;
        this.isRightIndeterminate = indeterminate;
      },
    },
    currentRightPageNum() {
      this.currentRightList = this.pagingChage(
        this.rightAllList,
        this.currentRightPageNum
      );
    },
  },

  mounted() {
    const { id, companyNo, questionNum } = JSON.parse(Cookies.get("ORDER"));
    this.leftParams.orderId = id;
    this.leftParams.companyNo = companyNo;
    this.rightParams.orderId = id;
    this.rightParams.companyNo = companyNo;
    this.questionNum = questionNum;
    this.$nextTick(() => {
      this.questionNum = questionNum;
      this.findList();
    });
    this.getDictList();
  },

  methods: {
    //标签刷选
    handleChange(val) {
      this.leftParams.pageNum = 1;
      this.leftParams.tags = val.map((item) => item[item.length - 1]);
    },
    findList() {
      this.$showLoading();
      Promise.all([this.leftList(), this.rightList()])
        .then(() => {
          this.$hideLoading();
          this.this.aboutMore();
        })
        .catch((res) => {
          console.log(res);
          this.isLeftLoading = false;
          this.isRightLoading = false;
        });
    },
    pageNumLeftChange(val) {
      if (val != this.leftParams.pageNum) {
        this.leftParams.pageNum = val;
        this.isLeftLoading = true;
      }
    },
    rightPageNumChange(val) {
      this.currentRightPageNum = val;
    },
    async leftList() {
      const res = await this.$API.orderAllHealthIQ(this.leftParams);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.isLeftLoading = false;
      const { total, records } = JSON.parse(Decrypt(res.data));
      records.map((item) => ((item.isTrue = false), (item.disabled = false)));
      this.leftAllList = records;
      this.leftTotal = +total;
      return res.data;
    },
    async rightList() {
      const res = await this.$API.orderSelectHealthIQ(this.rightParams);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.isRightLoading = false;
      const records = JSON.parse(Decrypt(res.data));
      records.map((item) => ((item.isTrue = false), (item.disabled = false)));
      this.rightAllList = records;
      this.currentRightPageNum = 1;
      this.currentRightList = this.pagingChage(
        this.rightAllList,
        this.currentRightPageNum
      );
      return res.data;
    },
    async leftClick() {
      this.rightAllList = this.rightAllList.filter((item) => !item.isTrue);
      this.aboutMore();
      this.currentRightList = this.pagingChage(
        this.rightAllList,
        this.currentRightPageNum
      );
      let rightAllList = JSON.parse(JSON.stringify(this.rightAllList));
      rightAllList = rightAllList.map((item) => {
        return item.questionNo;
      });
      const params = {
        orderId: this.leftParams.orderId,
        questionNos: rightAllList,
        createUser: "100",
      };

      const res = await this.$API.sassOrderHealthIQBaseVChoice(params);

      if (res.status !== 200) {
        return this.$message.error("提交失败！");
      }
      this.isLeftLoading = true;
      this.leftList();
    },
    rightClick() {
      const leftAllList = JSON.parse(
        JSON.stringify(this.leftAllList.filter((item) => item.isTrue == true))
      );
      this.rightClickDate(leftAllList);
    },

    rightClickDate(leftAllList) {
      const rightAllList = this.sortArray(this.rightAllList, leftAllList);
      if (rightAllList.length > this.questionNum) {
        this.$alert(
          "当前所选题目大于可选题目数量，已自动保留到可最大保存的题目数量",
          "标题名称",
          {
            confirmButtonText: "确定",
            callback: () => {
              this.rightAllList = rightAllList;
              this.rightAllList.length = this.questionNum;
              this.currentRightPageNum = 1;
              this.currentRightList = this.pagingChage(
                this.rightAllList,
                this.currentRightPageNum
              );
              this.aboutMore();
            },
          }
        );
      } else {
        this.rightAllList = rightAllList;
        this.currentRightPageNum = 1;
        this.currentRightList = this.pagingChage(
          this.rightAllList,
          this.currentRightPageNum
        );
        this.aboutMore();
      }
    },

    async subClick() {
      let rightAllList = JSON.parse(JSON.stringify(this.rightAllList));
      rightAllList = rightAllList.map((item) => {
        return item.questionNo;
      });
      const params = {
        orderId: this.leftParams.orderId,
        questionNos: rightAllList,
        createUser: "100",
      };

      const res = await this.$API.sassOrderHealthIQBaseVChoice(params);

      if (res.status !== 200) {
        return this.$message.error("提交失败！");
      }

      this.$alert("前往查看选中题目列表", "提交成功", {
        confirmButtonText: "确定",
        callback: () => {
          this.$router.push({
            path: "/business/order/companyOrder/getTopic",
          });
        },
      });
    },
    // 合并去重
    sortArray(arr1, arr2) {
      const sortArray = arr1.reduce((acc, cur) => {
        const target = acc.find((e) => e.questionNo === cur.questionNo);
        if (target) {
          Object.assign(target, cur);
        } else {
          acc.push(cur);
        }
        return acc;
      }, arr2);

      return sortArray;
    },

    // 左右比较
    aboutMore() {
      this.leftAllList.map((leftItem) => {
        const isTrue = this.rightAllList.find((rightItem) => {
          return rightItem.questionNo === leftItem.questionNo;
        });
        if (isTrue) {
          leftItem.isTrue = false;
          leftItem.disabled = true;
        } else {
          leftItem.isTrue = false;
          leftItem.disabled = false;
        }
        return leftItem;
      });

      // const leftList = this.leftAllList;
    },

    // 当前分页数据
    pagingChage(List, pageNum) {
      let list = [];
      if (List instanceof Array) {
        list = List.slice((pageNum - 1) * 10, (pageNum - 1) * 10 + 10);
      }
      return list;
    },
    // 计算全选状态
    setCheck(AllList) {
      let checkAll =
        (AllList.length > 0 && AllList.every((item) => item.isTrue == true)) ||
        false;
      let indeterminate =
        (!checkAll && AllList.some((item) => item.isTrue == true)) || false;
      return { checkAll, indeterminate };
    },
    // 标签数据
    async getDictList() {
      const res = await this.$API.getDictList({ dictType: 1 });
      if (res.status !== 200) return "";
      this.dictList = res.data;
    },
    leftCheckChange(val) {
      this.leftAllList.map((item) => (item.isTrue = val));
    },
    checkboxChange(val) {
      this.currentRightList.map((item) => (item.isTrue = val));
    },
  },
};
</script>

<style lang="scss" scoped>
.topic {
  width: 100%;
  height: calc(100vh - 100px);
  background: #fff;
}
.transfer {
  height: 600px;
}
.transfer_index {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bottom {
  padding-right: 50px;
  width: 90%;
  display: flex;
  justify-content: end;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
.header_table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
